import type { AppProps } from 'next/app'
import Head from 'next/head'
import { Provider } from 'react-redux'
import { store } from '@redux/store'
import Script from 'next/script'
import config from '@config'

function NextApp({ Component, pageProps }: AppProps)
{
    return (
        <Provider store={store}>
            <Head>
                <meta charSet="utf-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, minimum-scale=1, initial-scale=1, shrink-to-fit=no" />
            </Head>

            <Script strategy="beforeInteractive" src={config.defaultPublicDirBaseUrl + 'dedafo/assets/js/jquery.min.js'}></Script>
            <Script strategy="beforeInteractive" src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js"></Script>
            <Script strategy="beforeInteractive" src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.min.js"></Script>
            <Script strategy="beforeInteractive" src={config.defaultPublicDirBaseUrl + 'dedafo/assets/js/jquery.unveil.js'}></Script>
            <Script strategy="beforeInteractive" src={config.defaultPublicDirBaseUrl + 'dedafo/assets/js/aos.js'}></Script>
            <Script strategy="beforeInteractive" src={config.defaultPublicDirBaseUrl + 'dedafo/assets/js/swiper.min.js'}></Script>
            <Script strategy="beforeInteractive" src={config.defaultPublicDirBaseUrl + 'dedafo/assets/js/jquery.magnific-popup.min.js'}></Script>
            <Script strategy="beforeInteractive" src={config.defaultPublicDirBaseUrl + 'dedafo/assets/js/owl.carousel.min.js'}></Script>
            

            <Component {...pageProps} />
        </Provider>
    )
}

export default NextApp
